<template>
  <Component
    :is="tag"
    ref="tabsEl"
    class="tabs border-b lg:border-b-0 relative"
  >
    <slot />
    <ClientOnly>
      <div
        v-if="!isMobileOrTablet"
        ref="indicator"
        class="tabs-indicator"
        :style="indicatorStyle"
      />
    </ClientOnly>
  </Component>
</template>

<script lang="ts" setup>
import { falsy } from '~/helpers/type'

const props = withDefaults(
  defineProps<{
    tag?: string
    queryKey?: string
  }>(),
  {
    tag: 'section',
    queryKey: undefined,
  },
)
const { isMobileOrTablet } = useViewport()
const route = useRoute()
const router = useRouter()

const active = ref<string[]>([])
const tabsEl = ref<HTMLDivElement | null>(null)

if (props.queryKey) {
  const activeFromQuery = route.query[props.queryKey]
  if (activeFromQuery) {
    if (Array.isArray(activeFromQuery)) {
      active.value = activeFromQuery.filter(falsy).map((v) => v?.toString())
    } else {
      active.value = [activeFromQuery]
    }
  }
}

export type TabContext = {
  onToggle: (id: string) => void
  active: globalThis.Ref<string[]>
}

const context: TabContext = {
  onToggle(id: string) {
    if (isMobileOrTablet.value) {
      if (active.value.includes(id)) {
        active.value = active.value.filter((v) => v !== id)
      } else {
        active.value.push(id)
      }
      return
    }

    active.value = [id]
    if (props.queryKey) {
      router.replace({ query: { [props.queryKey]: active.value } })
    }
  },
  active,
}

provide('tabContext', context)

const activeId = computed<string | undefined>(() => {
  if (active.value[0]) {
    return active.value[0]
  }
  if (tabsEl.value) {
    const match = document.querySelector('[data-tabs-item]')
    if (match instanceof HTMLElement) {
      return match.dataset.tabsItem
    }
  }

  return undefined
})

const indicatorStyle = computed(() => {
  if (isMobileOrTablet.value || !tabsEl.value) {
    return null
  }

  if (!activeId.value) {
    return null
  }

  const element = document.querySelector(`[data-tabs-item="${activeId.value}"]`)
  if (element instanceof HTMLElement) {
    const rect = element.getBoundingClientRect()
    const tabsRect = tabsEl.value.getBoundingClientRect()
    return {
      width: rect.width + 'px',
      transform: `translateX(${rect.left - tabsRect.left}px)`,
      top: rect.height - 4 + 'px',
    }
  }

  return null
})
</script>

<style lang="postcss">
.tabs {
  border-bottom-width: 0.75px;
  @screen lg {
    @apply grid border-none;
    grid-template-columns: auto auto auto auto auto auto auto auto 1fr;
    grid-template-rows: auto 1fr;

    .tabs-item-button:first-child {
      @apply pl-0;
    }
  }
}

.tabs-indicator {
  @apply bg-black h-5 absolute top-0 left-0 transition-all duration-300 pointer-events-none;
}
</style>
